import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

export class ApiBaseService {
  // host
  protected readonly host: string;
  // base host
  protected readonly baseHost: string = environment.api.host;
  // delay
  protected readonly delay: number = environment.api.delay;

  constructor(
    path = '',
  ) {
    this.host = this.baseHost + path;
  }

  /**
   * return endpoint
   * @param path path
   */
  endpoint(path: string) {
    return this.host + path;
  }

  /**
   * return mock response
   * @param response response
   */
  returnMockResponse<T>(response: T = {} as any): Observable<T> {
    return of(response)
      .pipe(delay(this.delay));
  }
}
