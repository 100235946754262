import {ClientDetail} from '../../../../models/client-detail';
import {SubscriptionInventory, SubscriptionKeys} from '../../../../utils/subscribe.util';
import {Store} from '@ngrx/store';
import {clientStoreSelectors} from '../../../../stores/client-store/client-store.selectors';
import {ChangeDetectorRef, OnDestroy, OnInit} from '@angular/core';
import {ClientEditState} from '../../../../models/client-edit-state';
import {combineLatest} from 'rxjs';
import {clientStoreActions} from '../../../../stores/client-store/client-store.actions';

export class ClientInfoBaseComponent implements OnInit, OnDestroy {
  // client
  client: ClientDetail;
  // client created state
  clientCreated = false;
  // client edit state
  clientEditState: ClientEditState;
  // editing state
  editing = false;
  // tab
  tab: keyof ClientEditState;
  // base inventory
  private _inventory: SubscriptionInventory = new SubscriptionInventory<SubscriptionKeys>();

  constructor(
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.getStoredClientInfo();
  }

  ngOnDestroy(): void {
    this._inventory.unSubscribeAll();
  }

  /**
   * get client from store
   */
  get client$() {
    return this.store.select(clientStoreSelectors.client);
  }

  /**
   * get client created
   */
  get clientCreated$() {
    return this.store.select(clientStoreSelectors.clientCreated);
  }

  /**
   * get edit state
   */
  get clientEditState$() {
    return this.store.select(clientStoreSelectors.clientEditState);
  }

  /**
   * get stored client info
   */
  getStoredClientInfo() {
    const sub = combineLatest([
      this.client$,
      this.clientCreated$,
      this.clientEditState$,
    ]).subscribe(res => {
      if (res) {
        this.client = res[0];
        this.clientCreated = res[1];
        this.clientEditState = res[2];

        if (this.clientCreated && this.clientEditState) {
          this.checkEditCompleted();
        }

        // set as editing when loaded
        if (this.client && this.clientEditState && this.clientEditState[this.tab]) {
          this.viewAsEditMode();
        }
      }
    });

    this._inventory.store('getStoredClientInfo', sub);
  }


  /**
   * cancel button click
   */
  cancelConfig(){

  }
  /**
   * detect change will be called when editing state changed to detect accordion heights
   */
  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * this method should be overridden from the extended component
   */
  viewAsEditMode() {}

  /**
   * mark as editing completed
   */
  markAsCompleted() {
    this.store.dispatch(clientStoreActions.setClientEditState({
      clientEditState: {
        ...this.clientEditState,
        [this.tab]: false,
      },
    }));
  }

  /**
   * check edit completed
   */
  private checkEditCompleted() {
    // all completed will be true
    // when all client edit state is false
    const allCompleted = Object.keys(this.clientEditState).every(key => !this.clientEditState[key]);

    if (allCompleted) {
      // if all completed,
      // set clientCreated state as false
      this.store.dispatch(clientStoreActions.setClientCreated({clientCreated: false}));
    }
  }
}
