import {OnDestroy, OnInit} from '@angular/core';
import {MainStoreService} from '../../../../services/main-store-service/main-store.service';
import {SubscriptionInventory, SubscriptionKeys} from '../../../../utils/subscribe.util';
import {User} from '../../../../models/user';

export class UserProfileBaseComponent implements OnInit, OnDestroy {
  // user profile
  userProfile: User;
  // inventory
  private _inventory: SubscriptionInventory = new SubscriptionInventory<SubscriptionKeys>();

  constructor(
    protected mainStoreService: MainStoreService,
  ) {
  }

  ngOnInit(): void {
    this.getUserProfile();
  }

  ngOnDestroy(): void {
    this._inventory.unSubscribeAll();
  }

  get userProfile$() {
    return this.mainStoreService.userProfile$;
  }

  get isProfileEditable$() {
    return this.mainStoreService.isProfileEditable$;
  }

  /**
   * get user profile from store
   */
  protected getUserProfile() {
    const sub = this.userProfile$
      .subscribe(user => {
        this.userProfile = user;

        if (this.userProfile) {
          this.onReady();
        }
      });

    this._inventory.store('userProfile$', sub);
  }

  /**
   * should override from extended method
   */
  protected onReady() { }
}
