import {createSelector} from '@ngrx/store';
import {SiteStoreState} from './site-store.reducer';
import {SiteStoreKey} from './site-store.actions';

export const selectSiteStore = (state): SiteStoreState => state[SiteStoreKey];

export const siteStoreSelectors = {
  // select site from store
  site: createSelector(
    selectSiteStore,
    state => state.site,
  ),
  // select client id from store
  clientId: createSelector(
    selectSiteStore,
    state => state.clientId,
  ),
  // select switchableSites from store
  switchableSites: createSelector(
    selectSiteStore,
    state => state.switchableSites,
  ),
  // select available management systems
  availableManagementSystems: createSelector(
    selectSiteStore,
    state => state.availableManagementSystems,
  ),
  // select site edit state
  siteEditState: createSelector(
    selectSiteStore,
    state => state.siteEditState,
  ),
  // select site created state
  siteCreated: createSelector(
    selectSiteStore,
    state => state.siteCreated,
  ),
};
