import {StaticTableComponent} from '../../../../components/application/common/static-table/static-table.component';
import {ChangeDetectorRef, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {clientStoreSelectors} from '../../../../stores/client-store/client-store.selectors';
import {ClientDetail} from '../../../../models/client-detail';
import {SubscriptionInventory, SubscriptionKeys} from '../../../../utils/subscribe.util';
import {ClientEditState} from '../../../../models/client-edit-state';
import {combineLatest} from 'rxjs';
import {clientStoreActions} from '../../../../stores/client-store/client-store.actions';

export class ClientStaticTableComponent<T> extends StaticTableComponent<T> implements OnInit, OnDestroy {
  // client detail
  client: ClientDetail;
  // client created state
  clientCreated = false;
  // client edit state
  clientEditState: ClientEditState;
  // current tab name
  tab: keyof ClientEditState;
  // inner inventory
  private _inventory: SubscriptionInventory = new SubscriptionInventory<SubscriptionKeys>();

  constructor(
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
    super(
      changeDetectorRef,
    );
  }

  ngOnInit(): void {
    this.getStoredClientInfo();
  }

  ngOnDestroy(): void {
    this._inventory.unSubscribeAll();
  }

  /**
   * get client
   */
  get client$() {
    return this.store.select(clientStoreSelectors.client);
  }

  /**
   * get client created
   */
  get clientCreated$() {
    return this.store.select(clientStoreSelectors.clientCreated);
  }

  /**
   * get client edit state
   */
  get clientEditState$() {
    return this.store.select(clientStoreSelectors.clientEditState);
  }

  /**
   * return true when edit mode for created client
   */
  get editCreatedClient() {
    return this.client && this.clientCreated && this.clientEditState && this.clientEditState[this.tab];
  }

  /**
   * detect changes
   */
  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * get stored client info
   */
  getStoredClientInfo() {
    const sub = combineLatest([
      this.client$,
      this.clientCreated$,
      this.clientEditState$,
    ]).subscribe(res => {
      if (res) {
        this.client = res[0];
        this.clientCreated = res[1];
        this.clientEditState = res[2];

        if (this.client) {
          this.setFilteredData('');
        }

        if (this.clientCreated && this.clientEditState) {
          this.checkEditCompleted();
        }
      }
    });

    this._inventory.store('getStoredClientInfo', sub);
  }

  /**
   * mark as editing completed
   */
  markAsCompleted() {
    this.store.dispatch(clientStoreActions.setClientEditState({
      clientEditState: {
        ...this.clientEditState,
        [this.tab]: false,
      },
    }));
  }

  /**
   * check edit completed
   */
  private checkEditCompleted() {
    // all completed will be true
    // when all client edit state is false
    const allCompleted = Object.keys(this.clientEditState).every(key => !this.clientEditState[key]);

    if (allCompleted) {
      // if all completed,
      // set clientCreated state as false
      this.store.dispatch(clientStoreActions.setClientCreated({clientCreated: false}));
    }
  }
}
