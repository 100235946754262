import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal.component';
import {ModalModule} from '../../../../shared-components/modal/modal.module';
import {FlatButtonModule} from '../../../../shared-components/flat-button/flat-button.module';
import {Nl2brModule} from '../../../../pipes/nl2br/nl2br.module';



@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    FlatButtonModule,
    Nl2brModule,
  ],
  entryComponents: [
    ConfirmModalComponent,
  ]
})
export class ConfirmModalModule { }
