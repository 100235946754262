import { Injectable } from '@angular/core';
import { ApiBaseService } from './base/api-base.service';
import { HttpClient } from '@angular/common/http';
import { SideAlertsType } from '../components/application/common/side-alerts/side-alerts.component';
import { SideAlertStatus } from '../models/side-alert-list-item';
import { map } from 'rxjs/operators';
import { AppColumnSortEvent } from '../shared-components/table/table.component';
import * as moment from 'moment';
import { HttpHelperService } from './base/http-helper.service';
import { each } from 'lodash';
import { ClientConfiguration } from '../models/client-configuration';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService extends ApiBaseService {
  constructor(private http: HttpClient, private httpHelper: HttpHelperService) {
    super('/alert');
  }

  /**
   * get side alert list
   * @param page page
   * @param size size
   * @param type type to get
   * @param id id of client/site. recent type doesn't need this field
   * @param filter alert status
   */
  getSideAlerts(
    page: number,
    size: number,
    type: SideAlertsType,
    id: string,
    filter: SideAlertStatus
  ) {
    const params: any = { pageNumber: page + 1, pageSize: size, short: true };
    if (type === 'recent') {
      params.alertTime = moment().subtract(24, 'hour').toISOString();
    } else if (type === 'client') {
      params.clientId = id;
    } else if (type === 'site') {
      params.siteId = id;
    }
    if (!!filter) {
      params.status = filter;
    }

    return this.httpHelper.get(`/alerts`, { params }).pipe(
      map((res) => {
        return {
          page: res.pageNumber + 1,
          size: res.pageSize,
          total: res.total,
          progress:
            filter === 'Cleared'
              ? 100
              : res.total <= 0
              ? 100
              : Math.floor((res.totalOfCleared / res.total) * 100),
          data: res.items,
        };
      })
    );
  }

  /**
   * get alert counts
   * @param type alert type
   * @param id client or site id
   */
  getAlertCounts(type: 'client' | 'site', id: string) {
    const params: any = { pageNumber: 1, pageSize: 1 };
    if (type === 'client') {
      params.clientId = id;
    } else if (type === 'site') {
      params.siteId = id;
    }
    return this.httpHelper.get(`/alerts`, { params }).pipe(
      map((res) => {
        return res.metadata;
      })
    );
  }

  /**
   * get configuration
   * @param type the type
   * @param clientId the client id
   * @param siteId the site id
   */
  getConfiguration(
    type: 'client' | 'site',
    clientId: string,
    siteId: string
  ): Observable<ClientConfiguration> {
    if (type === 'client') {
      return this.httpHelper.get(`/clients/${clientId}`).pipe(
        map((res) => {
          return res.configuration;
        })
      );
    }
    return this.httpHelper.get(`/clients/${clientId}/sites/${siteId}`).pipe(
      map((res) => {
        return res.configuration;
      })
    );
  }

  /**
   * get alerts
   * @param page page
   * @param size size
   * @param type alert type
   * @param sorts sort event
   * @param id client or site id
   * @param alertType alert type
   */
  getAlerts(
    page: number,
    size: number,
    sorts: AppColumnSortEvent,
    type: 'client' | 'site',
    id: string,
    alertType: string
  ) {
    const params: any = { pageNumber: page + 1, pageSize: size };
    each(sorts, (v, k) => {
      if (v) {
        params.sortBy = k;
        params.sortOrder = v;
      }
    });
    params[type === 'client' ? 'clientId' : 'siteId'] = id;
    if (alertType) {
      params.alertType = alertType;
    }

    return this.httpHelper.get(`/alerts`, { params }).pipe(
      map((res) => {
        return {
          page: res.pageNumber - 1,
          size: res.pageSize,
          total: res.total,
          new: res.metadata.new,
          active: res.metadata.active,
          cleared: res.metadata.cleared,
          data: res.items || [],
        };
      })
    );
  }

  /**
   * get assignable users
   */
  getAssignableUser(clientId: string, siteId: string, params: any) {
    return this.httpHelper.get(
      `/clients/${clientId}/sites/${siteId}/assignable-user-list`,
      { params }
    );
  }

  /**
   * assign user to alert
   * @param alertId alert id
   * @param users the users id
   */
  assignUser(alertId: string, users: string[]) {
    return this.httpHelper.put(`/alerts/${alertId}`, { assignedTo: users });
  }

  /**
   * get system status
   * @param type type of client or site
   * @param id client or site id
   */
  getSystemStatus(type: 'client' | 'site', id: string) {
    const params: any = {};
    if (type === 'client') {
      params.clientId = id;
    } else {
      params.siteId = id;
    }
    return this.httpHelper.get('/devices-statistics', { params });
  }

  /**
   * get available clear reasons
   */
  getAvailableClearReasons() {
    return this.returnMockResponse(['Solved', 'Invalid Alert']);
  }

  /**
   * clear alert
   * @param id alert id to clear]
   * @param body the clear reason
   */
  clearAlert(id: string, body) {
    return this.httpHelper.put(`/alerts/${id}`, {
      status: 'Cleared',
      reason: body.reason,
      detailed: body.description,
    });
  }

  /**
   * return alert detail
   * @param id alert id
   */
  getAlertDetail(id: string) {
    return this.httpHelper.get(`/alerts/${id}`);
  }

  /**
   * process short code
   * @param id the code id
   */
  processCode(id: string) {
    return this.httpHelper.post(`/a/${id}`, {});
  }
}
