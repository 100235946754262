import { Injectable } from '@angular/core';
import { HttpHelperService } from './base/http-helper.service';
import { FileService } from './file.service';
import { Observable } from 'rxjs';
import { SiteClimateProfile } from '../models/site-climate-profile';

@Injectable({
  providedIn: 'root',
})
export class ClimateProfileService {
  constructor(private httpHelper: HttpHelperService) {}

  /**
   * create climate profile
   * @param body the climate profile body
   */
  createClimateProfile(body) {
    return this.httpHelper.post('/climate-profiles', body);
  }

  deleteClimateProfile(id) {
    return this.httpHelper.delete(`/climate-profiles/${id}`);
  }

  /**
   * update climate profile
   * @param id climate profile id
   * @param fields updated fields
   */
  updateClimateProfile(id: string, fields) {
    return this.httpHelper.put(`/climate-profiles/${id}`, fields);
  }

  /**
   * get ClimateProfile by token
   * @param id token
   * @param ignoreError ignore error
   */
  getClimateProfileById(
    id: string,
    ignoreError: boolean
  ): Observable<SiteClimateProfile> {
    return this.httpHelper.get('/climate-profiles/' + id, { ignoreError });
  }

  /**
   * get ClimateProfile list by token
   * @param id token
   * @param ignoreError ignore error
   */
  getClimateProfileListBySiteId(
    id: string,
    ignoreError: boolean
  ): Observable<SiteClimateProfile> {
    return this.httpHelper.get('/climate-profile-list/' + id, { ignoreError });
  }

  /**
   * Enable VIP Override
   */
  enableVIPOverrideForRoom(roomId: string, ignoreError: boolean) {
    return this.httpHelper.post(
      '/climate-profiles/enable-vip-override/' + roomId,
      { ignoreError }
    );
  }
  /**
   * Disable VIP Override
   */
  disableVIPOverrideForRoom(alertKey: string, ignoreError: boolean) {
    return this.httpHelper.post(
      '/climate-profiles/disable-vip-override/' + alertKey,
      { ignoreError }
    );
  }
}
